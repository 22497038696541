import axios from 'axios'
import token from '@/utils/token'
import { Toast } from 'vant'
// import router from '@/router'
import dayjs from 'dayjs'
import { refresh } from '@/api/user'

// create an axios instance
let baseURL = process.env.VUE_APP_BASE_API
if (baseURL) { baseURL = baseURL + '/api' } else { baseURL = '/api' }

const service = axios.create({
  baseURL: baseURL, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

/* 是否正在刷新的标志*/
window.isRefreshing = false
/* 存储请求的数组*/
let refreshSubscribers = []

/* 将所有的请求都push到数组中,其实数组是[function(token){}, function(token){},...]*/
function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb)
}
/* 数组中的请求得到新的token之后自执行，用新的token去请求数据*/
function onRefreshed(tokenData) {
  refreshSubscribers.map(cb => cb(tokenData))
}

let msgIsShowing = false

// request interceptor
service.interceptors.request.use(
  config => {
    let tokenData = token.get()
    if (tokenData) {
      config.headers.Authorization = tokenData.token_type + ' ' + tokenData.access_token
      const regex = /user\/refresh/
      if (tokenData.expires_in <= dayjs().unix() + 300 && !regex.test(config.url)) {
        // token已过期，刷新token
        if (!window.isRefreshing) {
          window.isRefreshing = true
          refresh().then(res => {
            if (res.code === 1) {
              window.isRefreshing = false
              token.set(res.data)
              tokenData = res.data
              /* 执行数组里的请求，重新发起被挂起的请求*/
              onRefreshed(tokenData)
            }

            /* 执行onRefreshed函数后清空数组中保存的请求 */
            refreshSubscribers = []
          })
        }
        const retry = new Promise((resolve, reject) => {
          /* (tokenData) => {...}这个函数就是cb*/
          subscribeTokenRefresh((tokenData) => {
            if (tokenData) {
              config.headers.Authorization = tokenData.token_type + ' ' + tokenData.access_token
            }
            /* 将请求挂起*/
            resolve(config)
          })
        })
        return retry
      } else {
        return config
      }
    } else {
      return config
    }
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    if (process.env.NODE_ENV === 'development') { console.log(res) }

    // if the custom code is not 1, it is judged as an error.
    if (res.code !== 1) {
      const showMsgDuration = 1500
      if (res.code === 10002 || res.code === 10003) {
        if (!msgIsShowing) {
          msgIsShowing = true

          Toast.fail(res.msg)
          setTimeout(() => {
            msgIsShowing = false
          }, showMsgDuration)
        }
        // 清除token
        token.rm()
        location.reload()
        // router.replace('/login')
        return res
      } else if (res.code !== 10008 && res.code !== 10005) {
        Toast.fail(res.msg)
      }

      // 10001 = 验证码错误或用户名密码错误(刷新验证码)
      // 10009 = 拣货数量与订单数量不一致
      // 10008 = 来货验收成本单价上下浮动超过5%
      // 10005 = 驳回重交二次确认
      if (res.code === 10001 || res.code === 10009 || res.code === 10008 || res.code === 10005 || res.code === 10004) { return res }

      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Toast.fail(error.message)
    return Promise.reject(error)
  }
)

export default service
