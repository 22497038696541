import request from '@/utils/request'

export default {

  login(data) {
    return request({
      method: 'post',
      url: 'user/login',
      data: data
    })
  },
  info() {
    return request({
      method: 'get',
      url: 'user/info'
    })
  },
  changeWarehouse(data) {
    return request({
      method: 'put',
      url: 'user/warehouse',
      data: data
    })
  },
  payQrcode(file) {
    const form = new FormData()
    form.append('qrcode', file)
    return request({
      method: 'post',
      url: 'user/pay-qrcode',
      data: form,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  modifyPwd(data) {
    return request({
      method: 'post',
      url: 'user/password/modify',
      data: data
    })
  }
}

export function refresh() {
  return request({
    method: 'post',
    url: 'user/refresh'
  })
}

export function logout() {
  return request({
    method: 'post',
    url: 'user/logout'
  })
}

export function getStores() {
  return request({
    method: 'get',
    url: 'login/store'
  })
}
